import React from "react";

import "./Terms.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import { Theme } from "../../types/enums";


export default function Terms() {
  return (
    <div className="terms-page">
      <Navbar theme={Theme.light} />
      <div className="terms-page-main">
        <h1 className="nw-terms-title">Terms and Conditions</h1>
        <p className="nw-terms-section-header">
          Who we are and how to contact us
        </p>

        <p>
          www.networktheapp.com is a site operated by Network Limited ("We"). We
          are registered in England and Wales and have our registered office at
          11 Bowhouse Court, Cofferdam Way, London, SE8 3GQ. To contact us,
          please email support@networktheapp.com or telephone our customer
          service line on +447506460833.
        </p>

        <p className="nw-terms-section-header">
          By using our site you accept these terms
        </p>

        <p>
          By using our site, you confirm that you accept these terms of use and
          that you agree to comply with them.
        </p>

        <p>If you do not agree to these terms, you must not use our site.</p>

        <p>
          We recommend that you print a copy of these terms for future
          reference.
        </p>

        <p className="nw-terms-section-header">
          There are other terms that may apply to you.
        </p>

        <p>
          These terms of use refer to the following additional terms, which also
          apply to your use of our site:
        </p>

        <p>
          · Our Privacy Policy www.networktheapp.com/privacy-policy. See
          further under How we may use your personal information.
        </p>

        <p>
          · Our Acceptable Use Policy www.networktheapp.com/acceptable-use,
          which sets out the permitted uses and prohibited uses of our site.
          When using our site, you must comply with this Acceptable Use Policy.
        </p>

        <p>
          · Our Cookie Policy www.networktheapp.com/cookie-policy, which
          sets out information about the cookies on our site.
        </p>

        <p className="nw-terms-section-header">
          We may make changes to these terms
        </p>

        <p>
          We amend these terms from time to time. Every time you wish to use our
          site, please check these terms to ensure you understand the terms that
          apply at that time. The latest version of these terms were created on
          the 1st April 2022.
        </p>

        <p className="nw-terms-section-header">
          We may make changes to our site
        </p>

        <p>
          We may update and change our site from time to time to reflect changes
          to our products, our users' needs and our business priorities. Where
          such changes would result in a material change to terms previously
          agreed to, we shall make reasonable efforts to inform you of such
          changes.
        </p>

        <p className="nw-terms-section-header">
          We may suspend or withdraw our site
        </p>

        <p>Our site is made available free of charge.</p>

        <p>
          We do not guarantee that our site, or any content on it, will always
          be available or be uninterrupted. We may suspend or withdraw or
          restrict the availability of all or any part of our site for business
          and operational reasons. We will try to give you reasonable notice of
          any suspension or withdrawal.
        </p>

        <p>
          You are also responsible for ensuring that all persons who access our
          site through your internet connection are aware of these terms of use
          and other applicable terms and conditions, and that they comply with
          them.
        </p>

        <p className="nw-terms-section-header">
          We may transfer this agreement to someone else
        </p>

        <p>
          We may transfer our rights and obligations under these terms to
          another organisation. We will always tell you in writing if this
          happens and we will ensure that the transfer will not affect your
          rights under the contract.
        </p>

        <p className="nw-terms-section-header">
          You must keep your account details safe
        </p>

        <p>
          If you choose, or you are provided with, a user identification code,
          password or any other piece of information as part of our security
          procedures, you must treat such information as confidential. You must
          not disclose it to any third party.
        </p>

        <p>
          We have the right to disable any user identification code or password,
          whether chosen by you or allocated by us, at any time, if in our
          reasonable opinion you have failed to comply with any of the
          provisions of these terms of use.
        </p>

        <p>
          If you know or suspect that anyone other than you knows your user
          identification code or password, you must promptly notify us at support@networktheapp.com
        </p>

        <p className="nw-terms-section-header">
          How you may use material on our site
        </p>

        <p>
          We are the owner or the licensee of all intellectual property rights
          in our site, and in the material published on it. Those works are
          protected by copyright laws and treaties around the world. All such
          rights are reserved.
        </p>

        <p>
          You may print off one copy, and may download extracts, of any page(s)
          from our site for your personal use and you may draw the attention of
          others within your organisation to content posted on our site.
        </p>

        <p>
          You must not modify the paper or digital copies of any materials you
          have printed off or downloaded in any way, and you must not use any
          illustrations, photographs, video or audio sequences or any graphics
          separately from any accompanying text.
        </p>

        <p>
          Our status (and that of any identified contributors) as the authors of
          content on our site must always be acknowledged (except where the
          content is user-generated).
        </p>

        <p>
          You must not use any part of the content on our site for commercial
          purposes without obtaining a licence to do so from us or our
          licensors.
        </p>

        <p>
          If you print off, copy, download, share or repost any part of our site
          in breach of these terms of use, your right to use our site will cease
          immediately and you must, at our option, return or destroy any copies
          of the materials you have made.
        </p>

        <p className="nw-terms-section-header">
          No text or data mining, or web scraping
        </p>

        <p>
          You shall not conduct, facilitate, authorise or permit any text or
          data mining or web scraping in relation to our site or any services
          provided via, or in relation to, our site. This includes using (or
          permitting, authorising or attempting the use of):
        </p>

        <p>
          · Any "robot", "bot", "spider", "scraper" or other automated device,
          program, tool, algorithm, code, process or methodology to access,
          obtain, copy, monitor or republish any portion of the site or any
          data, content, information or services accessed via the same.
        </p>

        <p>
          · Any automated analytical technique aimed at analysing text and data
          in digital form to generate information which includes but is not
          limited to patterns, trends and correlations.
        </p>

        <p>
          The provisions in this clause should be treated as an express
          reservation of our rights in this regard, including for the purposes
          of Article 4(3) of Digital Copyright Directive ((EU) 2019/790).
        </p>

        <p>
          This clause shall not apply insofar as (but only to the extent that)
          we are unable to exclude or limit text or data mining or web scraping
          activity by contract under the laws which are applicable to us.
        </p>

        <p className="nw-terms-section-header">
          Do not rely on information on this site
        </p>

        <p>
          The content on our site is provided for general information only. It
          is not intended to amount to advice on which you should rely. You must
          obtain professional or specialist advice before taking, or refraining
          from, any action on the basis of the content on our site.
        </p>

        <p>
          Although we make reasonable efforts to update the information on our
          site, we make no representations, warranties or guarantees, whether
          express or implied, that the content on our site is accurate, complete
          or up to date.
        </p>

        <p className="nw-terms-section-header">
          We are not responsible for websites we link to
        </p>

        <p>
          Where our site contains links to other sites and resources provided by
          third parties, these links are provided for your information only.
          Such links should not be interpreted as approval by us of those linked
          websites or information you may obtain from them.
        </p>

        <p>We have no control over the contents of those sites or resources.</p>

        <p className="nw-terms-section-header">
          User-generated content is not approved by us
        </p>

        <p>
          This website may include information and materials uploaded by other
          users of the site, including to bulletin boards and chat rooms. This
          information and these materials have not been verified or approved by
          us. The views expressed by other users on our site do not represent
          our views or values.
        </p>

        <p className="nw-terms-section-header">
          How to complain about content uploaded by other users
        </p>

        <p>
          If you wish to complain about content uploaded by other users, please
          contact us via the contact details given above.
        </p>

        <p className="nw-terms-section-header">
          How we may use your personal information
        </p>

        <p>
          We will only use your personal information as set out in our Privacy
          Policy.
        </p>

        <p className="nw-terms-section-header">Uploading content to our site</p>

        <p>
          Whenever you make use of a feature that allows you to upload content
          to our site, or to make contact with other users of our site, you must
          comply with the content standards set out in our Acceptable Use
          Policy.
        </p>

        <p>
          You warrant that any such contribution does comply with those
          standards, and you will be liable to us and indemnify us for any
          breach of that warranty. This means you will be responsible for any
          loss or damage we suffer as a result of your breach of warranty.
        </p>

        <p>
          Any content you upload to our site will be considered non-confidential
          and non-proprietary. You retain all of your ownership rights in your
          content, but you are required to grant us a limited licence to use,
          store and copy that content and to distribute and make it available to
          third parties.
        </p>

        <p>
          We also have the right to disclose your identity to any third party
          who is claiming that any content posted or uploaded by you to our site
          constitutes a violation of their intellectual property rights, or of
          their right to privacy.
        </p>

        <p>
          We have the right to remove any posting you make on our site if, in
          our opinion, your post does not comply with the content standards set
          out in our Acceptable Use Policy.
        </p>

        <p className="nw-terms-section-header">
          Rights you are giving us to use material you upload
        </p>

        <p>
          When you upload or post content to our site, you grant us the
          following rights to use that content:
        </p>

        <p>
          · a worldwide, non-exclusive, royalty-free, transferable licence to
          use, reproduce, distribute, prepare derivative works of, display, and
          perform that user-generated content in connection with the service
          provided by the website and across different media including to
          promote the site or the service, forever
        </p>

        <p className="nw-terms-section-header">
          We are not responsible for viruses and you must not introduce them
        </p>

        <p>
          We do not guarantee that our site will be secure or free from bugs or
          viruses.
        </p>

        <p>
          You are responsible for configuring your information technology,
          computer programmes and platform to access our site. You should use
          your own virus protection software.
        </p>

        <p>
          You must not misuse our site by knowingly introducing viruses,
          trojans, worms, logic bombs or other material that is malicious or
          technologically harmful. You must not attempt to gain unauthorised
          access to our site, the server on which our site is stored or any
          server, computer or database connected to our site. You must not
          attack our site via a denial-of-service attack or a distributed
          denial-of service attack. By breaching this provision, you would
          commit a criminal offence under the Computer Misuse Act 1990. We will
          report any such breach to the relevant law enforcement authorities and
          we will co-operate with those authorities by disclosing your identity
          to them. In the event of such a breach, your right to use our site
          will cease immediately.
        </p>

        <p className="nw-terms-section-header">
          Rules about linking to our site
        </p>

        <p>
          You may link to our home page, provided you do so in a way that is
          fair and legal and does not damage our reputation or take advantage of
          it.
        </p>

        <p>
          You must not establish a link in such a way as to suggest any form of
          association, approval or endorsement on our part where none exists.
        </p>

        <p>
          You must not establish a link to our site in any website that is not
          owned by you.
        </p>

        <p>
          Our site must not be framed on any other site, nor may you create a
          link to any part of our site other than the home page.
        </p>

        <p>
          We reserve the right to withdraw linking permission without notice.
        </p>

        <p>
          The website in which you are linking must comply in all respects with
          the content standards set out in our Acceptable Use Policy.
        </p>

        <p>
          If you wish to link to or make any use of content on our site other
          than that set out above, please contact us using the information given
          above.
        </p>

        <p className="nw-terms-section-header">
          Which country's laws apply to any disputes?
        </p>

        <p>
          If you are a consumer, please note that these terms of use, their
          subject matter and their formation, are governed by English law. You
          and we both agree that the courts of England and Wales will have
          exclusive jurisdiction.
        </p>
      </div>
      <Footer theme="light" />
    </div>
  );
}
