import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import { Theme } from "../../types/enums";

import "./AcceptableUsePolicy.css";

export default function AcceptableUsePolicy() {
  return (
    <div className="acceptable-use-policy-page">
      <Navbar theme={Theme.light} />
      <div className="acceptable-use-policy-page-main">
        <h1 className="nw-terms-title">Acceptable Use Policy</h1>
        <p className="nw-terms-section-header">
          PLEASE READ THE TERMS OF THIS POLICY CAREFULLY BEFORE USING THE SITE
        </p>

        <p className="nw-terms-section-header">What's in these terms?</p>

        <p>
          This acceptable use policy sets out the content standards that apply
          when you upload content to our site, make contact with other users on
          our site, link to our site, or interact with our site in any other
          way,
        </p>

        <p className="nw-terms-section-header">
          Who we are and how to contact us
        </p>

        <p>
          www.networktheapp.com is a site operated by Network Limited ("We"). We
          are registered in England and Wales and have our registered office at 11 Bowhouse Court, Cofferdam
          Way, London, SE8 3GQ.
        </p>

        <p>We are a limited company</p>

        <p className="nw-terms-section-header">
          By using our site you accept these terms
        </p>

        <p>
          By using our site, you confirm that you accept the terms of this
          policy and that you agree to comply with them.
        </p>

        <p>If you do not agree to these terms, you must not use our site.</p>

        <p>
          We recommend that you print a copy of these terms for future
          reference.
        </p>

        <p className="nw-terms-section-header">
          There are other terms that may apply to you
        </p>

        <p>
          Our Terms of website use www.networktheapp.com/terms also apply to your use of our site.
        </p>

        <p>We may make changes to the terms of this policy</p>

        <p>
          We amend these terms from time to time. Every time you wish to use our
          site, please check these terms to ensure you understand the terms that
          apply at that time. These terms were most recently updated on the 1st
          April 2022.
        </p>

        <p className="nw-terms-section-header">Prohibited uses</p>

        <p>You may not use our site:</p>

        <p>
          · In any way that breaches any applicable local, national or
          international law or regulation.
        </p>

        <p>
          · In any way that is unlawful or fraudulent or has any unlawful or
          fraudulent purpose or effect.
        </p>

        <p>
          · For the purpose of harming or attempting to harm minors in any way.
        </p>

        <p>· To bully, insult, intimidate or humiliate any person.</p>

        <p>
          · To transmit, or procure the sending of, any unsolicited or
          unauthorised advertising or promotional material or any other form of
          similar solicitation (spam).
        </p>

        <p>
          · To knowingly transmit any data, send or upload any material that
          contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
          spyware, adware or any other harmful programs or similar computer code
          designed to adversely affect the operation of any computer software or
          hardware.
        </p>

        <p>· To upload terrorist content.</p>

        <p>You also agree:</p>

        <p>
          · Not to reproduce, duplicate, copy or re-sell any part of our site in
          contravention of the provisions of our terms of website use.
        </p>

        <p>
          · Not to access without authority, interfere with, damage or disrupt:
        </p>

        <p>· any part of our site;</p>

        <p>· any equipment or network on which our site is stored;</p>

        <p>· any software used in the provision of our site; or</p>

        <p>
          · any equipment or network or software owned or used by any third
          party.
        </p>

        <p className="nw-terms-section-header">Content standards</p>

        <p>
          These content standards apply to any and all material which you
          contribute to our site (Contribution), and to any interactive services
          associated with it.
        </p>

        <p>
          The Content Standards must be complied with in spirit as well as to
          the letter. The standards apply to each part of any Contribution as
          well as to its whole.
        </p>

        <p>
          Network will determine, in its discretion, whether a Contribution
          breaches the content standards.
        </p>

        <p>A Contribution must:</p>

        <p>· Be accurate (where it states facts).</p>

        <p>· Be genuinely held (where it states opinions).</p>

        <p>
          · Comply with the law applicable in England and Wales and in any
          country from which it is posted.
        </p>

        <p>A Contribution must not:</p>

        <p>· Be defamatory of any person.</p>

        <p>· Be obscene, offensive, hateful or inflammatory.</p>

        <p>· Bully, insult, intimidate or humiliate.</p>

        <p>· Promote sexually explicit material.</p>

        <p>· Include child sexual abuse material.</p>

        <p>· Promote violence.</p>

        <p>
          · Promote discrimination based on race, sex, religion, nationality,
          disability, sexual orientation or age.
        </p>

        <p>
          · Infringe any copyright, database right or trade mark of any other
          person.
        </p>

        <p>· Be likely to deceive any person.</p>

        <p>
          · Breach any legal duty owed to a third party, such as a contractual
          duty or a duty of confidence.
        </p>

        <p>· Promote any illegal content or activity.</p>

        <p>· Be in contempt of court.</p>

        <p>
          · Be threatening, abuse or invade another's privacy, or cause
          annoyance, inconvenience or needless anxiety.
        </p>

        <p>
          · Be likely to harass, upset, embarrass, alarm or annoy any other
          person.
        </p>

        <p>
          · Impersonate any person or misrepresent your identity or affiliation
          with any person.
        </p>

        <p>
          · Give the impression that the Contribution emanates from Network, if
          this is not the case.
        </p>

        <p>
          · Advocate, promote, incite any party to commit, or assist any
          unlawful or criminal act such as (by way of example only) copyright
          infringement or computer misuse.
        </p>

        <p>
          · Contain a statement which you know or believe, or have reasonable
          grounds for believing, that members of the public to whom the
          statement is, or is to be, published are likely to understand as a
          direct or indirect encouragement or other inducement to the
          commission, preparation or instigation of acts of terrorism.
        </p>

        <p>
          For the avoidance of doubt, for any Contribution in the form of video
          content:
        </p>

        <p>
          · You must tell us immediately, if you upload a video containing any
          of the following: criminal material (relating to terrorism, sexual
          exploitation of children, child pornography, racism and xenophobia),
          unclassified or unclassifiable videos, videos rated R18 or suitable
          for R18 rating and other material that might impair the physical,
          mental or moral development of persons under the age of 18 (restricted
          material).
        </p>

        <p>· You must not upload a video containing harmful material.</p>

        <p>
          · You must not upload a video containing advertising for any of the
          following:
        </p>

        <p>
          · cigarettes and other tobacco products, electronic cigarettes or
          electronic cigarette refill containers, and prescription-only
          medicine; or
        </p>

        <p>· Any advertising included in a video you upload must not:</p>

        <p>· prejudice respect for human dignity;</p>

        <p>
          · include or promote discrimination based on sex, racial or ethnic
          origin, nationality, religion or belief, disability, age or sexual
          orientation;
        </p>

        <p>· encourage behaviour prejudicial to health or safety;</p>

        <p>
          · encourage behaviour grossly prejudicial to the protection of the
          environment;
        </p>

        <p>
          · cause physical, mental or moral detriment to persons under the age
          of 18;
        </p>

        <p>
          · directly exhort such persons to purchase or rent goods or services
          in a manner which exploits their inexperience or credulity;
        </p>

        <p>
          · directly encourage such persons to persuade their parents or others
          to purchase or rent goods or services;
        </p>

        <p>
          · exploit the trust of such persons in parents, teachers or others; or
        </p>

        <p>· unreasonably show such persons in dangerous situations.</p>

        <p>
          · You must use the functionality provided on our site to declare
          whether, as far as you know or can reasonably be expected to know, any
          video contains advertising.
        </p>

        <p className="nw-terms-section-header">Breach of this policy</p>

        <p>
          When we consider that a breach of this acceptable use policy has
          occurred, we may take such action as we deem appropriate.
        </p>

        <p>
          Failure to comply with this acceptable use policy constitutes a
          material breach of the terms of use www.networktheapp.com/terms
          upon which you are permitted to use our site, and may result in our
          taking all or any of the following actions:
        </p>

        <p>
          · Immediate, temporary or permanent withdrawal of your right to use
          our site.
        </p>

        <p>
          · Immediate, temporary or permanent removal of any Contribution
          uploaded by you to our site.
        </p>

        <p>· Issue of a warning to you.</p>

        <p>
          · Legal proceedings against you for reimbursement of all costs on an
          indemnity basis (including, but not limited to, reasonable
          administrative and legal costs) resulting from the breach.
        </p>

        <p>· Further legal action against you.</p>

        <p>
          · Disclosure of such information to law enforcement authorities as we
          reasonably feel is necessary or as required by law.
        </p>

        <p>
          We exclude our liability for all action we may take in response to
          breaches of this acceptable use policy. The actions we may take are
          not limited to those described above, and we may take any other action
          we reasonably deem appropriate.
        </p>

        <p className="nw-terms-section-header">
          How this contract can be transferred
        </p>

        <p>
          We can transfer our rights and obligations under these terms to any
          third party, provided this does not adversely affect your rights under
          these terms.
        </p>

        <p className="nw-terms-section-header">
          Which country's laws apply to any disputes?
        </p>

        <p>
          If you are a consumer, please note that the terms of this policy, its
          subject matter and its formation are governed by English law. You and
          we both agree that the courts of England and Wales will have exclusive
          jurisdiction except that if you are a resident of Northern Ireland you
          may also bring proceedings in Northern Ireland, and if you are
          resident of Scotland, you may also bring proceedings in Scotland.
        </p>

        <p>
          If you are a business, the terms of this policy, its subject matter
          and its formation (and any non-contractual disputes or claims) are
          governed by English law. We both agree to the exclusive jurisdiction
          of the courts of England and Wales.
        </p>
      </div>
      <Footer theme="light" />
    </div>
  );
}
