import React from "react";

import "./Landing.css";
import Navbar from "../../components/Navbar/Navbar";
import useDeviceType from "../../hooks/useDeviceType";
import Footer from "../../components/Footer/Footer";

export default function Landing() {
  const device = useDeviceType();
  return (
    <div className="landing-page">
      <Navbar />
      <div className="landing-main">
        <div className="landing-main-row">
          <div>
            <div className="landing-title-block">
              <h1 className="landing-header">Discover</h1>
              <h1 className="landing-header">your future</h1>
            </div>

            <p className="landing-subtext">
              Take control of your future. Discover. Connect.
            </p>
            <p className="landing-subtext">Grow. This is your Network.</p>
          </div>
          {device === "desktop" && (
            <div>
              <img src="./images/mobile.png" alt="mobile" height="770px" />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
