import React from "react";
import { useLocation, Link } from "react-router-dom";
import useDeviceType from "../../hooks/useDeviceType";
import { Theme } from "../../types/enums";

import "./Navbar.css";

export const NAVBAR_HEIGHT = 80;


export default function Navbar({ theme = Theme.dark }: { theme?: Theme }) {
  const device = useDeviceType();

  if (device === "mobile") {
    return <MobileNavbar />;
  }
  return <DesktopNavbar theme={theme} />;
}

function DesktopNavbar({ theme }: { theme: Theme }) {
  return (
    <nav className={`nav navbar navbar-${theme}`} style={{ height: NAVBAR_HEIGHT }}>
      <div className="nav-logo">
        {theme === "dark" &&
          <img src="./images/logo-copy.png" height="23px" alt="logo copy" />
        }
        {theme === "light" &&
          <img src="./images/logo-copy-light.png" height="23px" alt="logo copy" />
        }
      </div>
      <div className="navbar-links">
        <span style={{ marginRight: "80px", verticalAlign: "center" }}>
          <Navlink theme={theme} to="/" text="Home" />
        </span>
        <span>
          <Navlink theme={theme} text="Our Mission" />
        </span>
      </div>
    </nav>
  );
}

function MobileNavbar() {
  return (
    <nav className="nav mobile-navbar" style={{ height: NAVBAR_HEIGHT }}>
      <img src="./images/logo.png" height="21px" alt="logo copy" />
    </nav>
  );
}

function Navlink({ to, text, theme }: { to?: string; text: string; theme: Theme }) {
  const location = useLocation();
  const active = to === location.pathname;

  const style = {
    textDecoration: active ? "underline" : "none",
    fontFamily: active ? "MontserratSemiBold" : "MontserratRegular",
    margin: 0,
  };

  return (
    <>
      {to ? (
        <Link style={style} className={`nav-link nav-link-${theme}`} to={to}>
          {text}
        </Link>
      ) : (
        <p style={style}>{text}</p>
      )}
    </>
  );
}
