import { useLayoutEffect, useState } from "react";

function getDeviceType(width: number) {
  if (width < 641) {
    return "mobile";
  } else if (width < 961) {
    return "tablet";
  } else {
    return "desktop";
  }
}

export default function useDeviceType() {
  const [deviceType, setDeviceType] = useState(
    getDeviceType(window.innerWidth)
  );

  useLayoutEffect(() => {
    function onResize() {
      setDeviceType(getDeviceType(window.innerWidth));
    }
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return deviceType;
}
