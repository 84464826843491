import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import { Theme } from "../../types/enums";

import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-page">
      <Navbar theme={Theme.light} />
      <div className="privacy-policy-page-main">
        <h1 className="nw-terms-title">Privacy Policy</h1>
        <p>
          This privacy policy aims to give you information on how Network
          Limited collects and processes your personal data through your use of
          this website, including any data you may provide through this website
        </p>

        <p>
          This website is not intended for children and we do not knowingly
          collect data relating to children.
        </p>

        <p>
          It is important that you read this privacy policy together with any
          other privacy policy or fair processing policy we may provide on
          specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your
          data. This privacy policy supplements other notices and privacy
          policies and is not intended to override them.
        </p>

        <p className="nw-terms-section-header">Controller</p>

        <p>
          Network Limited is the controller and responsible for your personal
          data (collectively referred to as "Network", "we", "us" or "our" in
          this privacy policy).
        </p>

        <p>
          If you have any questions about this privacy policy, including any
          requests to exercise your legal rights please contact Network using
          the details set out below.
        </p>

        <p className="nw-terms-section-header">Contact details</p>

        <p>
          Full name of legal entity: Network Limited Email address:
          support@networktheapp.com
        </p>

        <p>
          Postal address: 11 Bowhouse Court, Cofferdam Way, London, SE8 3GQ.
        </p>

        <p>
          You have the right to make a complaint at any time to the Information
          Commissioner's Office (ICO), the UK regulator for data protection
          issues (www.ico.org.uk). We would, however, appreciate the chance to
          deal with your concerns before you approach the ICO so please contact
          us in the first instance.
        </p>

        <p className="nw-terms-section-header">
          Changes to the privacy policy and your duty to inform us of changes
        </p>

        <p>
          We keep our privacy policy under regular review. This version was last
          updated on the 1st April 2022.
        </p>

        <p>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us.
        </p>

        <p className="nw-terms-section-header">Third-party links</p>

        <p>
          This website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy policy of every website you visit.
        </p>

        <p className="nw-terms-section-header">
          1. The data we collect about you
        </p>

        <p>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </p>

        <p>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
        </p>

        <p>
          · Identity Data includes first name, maiden name, last name, username
          or similar identifier, marital status, title, date of birth and
          gender.
        </p>

        <p>· Contact Data includes email address and telephone numbers.</p>

        <p>
          · Technical Data includes (IP) address, your login data, browser type
          and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform, and other technology on the
          devices you use to access this website.
        </p>

        <p>
          · Profile Data includes your username and password, your interests,
          preferences, feedback and survey responses.
        </p>

        <p>
          · Usage Data includes information about how you use our website,
          products and services.
        </p>

        <p>
          · Marketing and Communications Data includes your preferences in
          receiving marketing from us and our third parties and your
          communication preferences.
        </p>

        <p>
          We also collect, use and share Aggregated Data such as statistical or
          demographic data for any purpose. Aggregated Data could be derived
          from your personal data but is not considered personal data in law as
          this data will not directly or indirectly reveal your identity. For
          example, we may aggregate your Usage Data to calculate the percentage
          of users accessing a specific website feature. However, if we combine
          or connect Aggregated Data with your personal data so that it can
          directly or indirectly identify you, we treat the combined data as
          personal data which will be used in accordance with this privacy
          policy.
        </p>

        <p>
          We do not collect any Special Categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </p>

        <p className="nw-terms-section-header">
          If you fail to provide personal data
        </p>

        <p>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with
          services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </p>

        <p className="nw-terms-section-header">
          2. How is your personal data collected?
        </p>

        <p>
          We use different methods to collect data from and about you including
          through:
        </p>

        <p>
          · <span className="nw-terms-text-bold">Direct interactions</span>. You
          may give us your Identity or Contact data by filling in forms or by
          corresponding with us by post, phone, email or otherwise. This
          includes personal data you provide when you
        </p>

        <p>· create an account on our website;</p>

        <p>· subscribe to our service or publications;</p>

        <p>· request marketing to be sent to you;</p>

        <p>· give us feedback or contact us.</p>

        <p>
          · <span className="nw-terms-text-bold">Automated technologies</span>{" "}
          or <span className="nw-terms-text-bold">interactions</span>. As you
          interact with our website, we will automatically collect Technical
          Data about your equipment, browsing actions and patterns. We collect
          this personal data by using cookies and other similar technologies.
        </p>

        <p>
          ·{" "}
          <span className="nw-terms-text-bold">
            Third parties or publicly available sources
          </span>
          . We will receive personal data about you from various third parties
          as set out below:
        </p>

        <p>Technical Data from the following parties:</p>

        <p>(a) analytics providers</p>

        <p>(b) advertising networks</p>

        <p>(c) search information providers</p>

        <p>· Identity and Contact Data from data brokers or aggregators</p>

        <p>
          · Identity and Contact Data from publicly available sources such as
          Companies House and the Electoral Register based inside the UK.
        </p>

        <p className="nw-terms-section-header">
          3. How we use your personal data
        </p>

        <p>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </p>

        <p>
          · Where we need to perform the contract we are about to enter into or
          have entered into with you.
        </p>

        <p>
          · Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.
        </p>

        <p>· Where we need to comply with a legal obligation.</p>

        <p>
          Generally, we do not rely on consent as a legal basis for processing
          your personal data although we will get your consent before sending
          third party direct marketing communications to you via email or text
          message. You have the right to withdraw consent to marketing at any
          time by contacting us.
        </p>

        <p className="nw-terms-section-header">
          Purposes for which we will use your personal data
        </p>

        <p>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases
          we rely on to do so. We have also identified what our legitimate
          interests are where appropriate.
        </p>

        <p>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than
          one ground has been set out in the table below.
        </p>

        <div className="nw-table">
          <div className="nw-table-row">
            <div className="nw-table-head">Purpose/Activity</div>
            <div className="nw-table-head">Type of data</div>
            <div className="nw-table-head">
              Lawful basis for processing including basis of legitimate interest
            </div>
          </div>
          <div className="nw-table-row">
            <div className="nw-table-cell">To register you as a new user</div>
            <div className="nw-table-cell">
              <p>(a) Identity</p>
              <p>(b) Contact</p>
            </div>
            <div className="nw-table-cell">
              Performance of a contract with you
            </div>
          </div>
          <div className="nw-table-row">
            <div className="nw-table-cell">
              <p>To manage our relationship with you which will include:</p>
              <p>
                (a) Notifying you about changes to our terms or privacy policy
              </p>
              <p>(b) Asking you to leave a review or take a survey</p>
            </div>
            <div className="nw-table-cell">
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Profile</p>
              <p>(d) Marketing and Communications</p>
            </div>
            <div className="nw-table-cell">
              <p>(a) Performance of a contract with you</p>
              <p>(b) Necessary to comply with a legal obligation</p>
              <p>
                (c) Necessary for our legitimate interests (to keep our records
                updated and to study how customers use our products/services)
              </p>
            </div>
          </div>
          <div className="nw-table-row">
            <div className="nw-table-cell">
              To administer and protect our business and this website (including
              troubleshooting, data analysis, testing, system maintenance,
              support, reporting and hosting of data)
            </div>
            <div className="nw-table-cell">
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Technical</p>
            </div>
            <div className="nw-table-cell">
              <p>
                (a) Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise)
              </p>
              <p>(b) Necessary to comply with a legal obligation</p>
            </div>
          </div>
          <div className="nw-table-row">
            <div className="nw-table-cell">
              <p>
                To deliver relevant website content and advertisements to you
                and measure or understand the effectiveness of the advertising
                we serve to you
              </p>
            </div>
            <div className="nw-table-cell">
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Profile</p>
              <p>(d) Usage</p>
              <p>(e) Marketing and Communications</p>
              <p>(f) Technical</p>
            </div>
            <div className="nw-table-cell">
              <p>
                Necessary for our legitimate interests (to study how customers
                use our products/services, to develop them, to grow our business
                and to inform our marketing strategy)
              </p>
            </div>
          </div>
          <div className="nw-table-row">
            <div className="nw-table-cell">
              <p>
                To use data analytics to improve our website, products/services,
                marketing, customer relationships and experiences
              </p>
            </div>
            <div className="nw-table-cell">
              <p>(a) Technical</p>
              <p>(b) Usage</p>
            </div>
            <div className="nw-table-cell">
              <p>
                Necessary for our legitimate interests (to define types of
                customers for our products and services, to keep our website
                updated and relevant, to develop our business and to inform our
                marketing strategy)
              </p>
            </div>
          </div>
          <div className="nw-table-row">
            <div className="nw-table-cell">
              <p>
                To make suggestions and recommendations to you about goods or
                services that may be of interest to you
              </p>
            </div>
            <div className="nw-table-cell">
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Technical</p>
              <p>(d) Usage</p>
              <p>(e) Profile</p>
              <p>(f) Marketing and Communications</p>
            </div>
            <div className="nw-table-cell">
              <p>
                Necessary for our legitimate interests (to develop our
                products/services and grow our business)
              </p>
            </div>
          </div>
        </div>

        <p className="nw-terms-section-header">Marketing</p>

        <p>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.
        </p>

        <p className="nw-terms-section-header">Promotional offers from us</p>

        <p>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which products, services and
          offers may be relevant for you (we call this marketing).
        </p>

        <p>
          You will receive marketing communications from us if you have
          requested information from us or you have not opted out of receiving
          that marketing.
        </p>

        <p className="nw-terms-section-header">Third-party marketing</p>

        <p>
          We will get your express opt-in consent before we share your personal
          data with any third party for marketing purposes.
        </p>

        <p className="nw-terms-section-header">Opting out</p>

        <p>
          You can ask us or third parties to stop sending you marketing messages
          at any time by following the opt-out links on any marketing message
          sent to you or by contacting us at any time.
        </p>

        <p className="nw-terms-section-header">Cookies</p>

        <p>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly. For more information about the
          cookies we use, please see our Cookie Policy.
        </p>

        <p className="nw-terms-section-header">Change of purpose</p>

        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
        </p>

        <p>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </p>

        <p>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </p>

        <p className="nw-terms-section-header">
          4. Disclosures of your personal data
        </p>

        <p>
          We may share your personal data with the parties set out below for the
          purposes set out in the table above.
        </p>

        <p>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>

        <p>
          Please contact us if you want further information on the specific
          mechanism used by us when transferring your personal data out of the
          UK.
        </p>

        <p className="nw-terms-section-header">5. Data security</p>

        <p>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </p>

        <p>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </p>

        <p className="nw-terms-section-header">6. Data retention</p>

        <p>How long will you use my personal data for?</p>

        <p>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
        </p>

        <p>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </p>

        <p className="nw-terms-section-header">7. Your legal rights</p>

        <p>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data.
        </p>

        <p>If you wish to exercise any such rights please contact us.</p>

        <p className="nw-terms-section-header">No fee usually required</p>

        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
        </p>

        <p className="nw-terms-section-header">What we may need from you</p>

        <p>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </p>

        <p className="nw-terms-section-header">Time limit to respond</p>

        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>

        <p className="nw-terms-section-header">YOUR LEGAL RIGHTS</p>

        <p>You have the right to:</p>

        <p>
          <span className="nw-terms-text-bold">Request access</span> to your
          personal data (commonly known as a "data subject access request").
          This enables you to receive a copy of the personal data we hold about
          you and to check that we are lawfully processing it.
        </p>

        <p>
          <span className="nw-terms-text-bold">Request correction</span> of the
          personal data that we hold about you. This enables you to have any
          incomplete or inaccurate data we hold about you corrected, though we
          may need to verify the accuracy of the new data you provide to us.
        </p>

        <p>
          <span className="nw-terms-text-bold">Request erasure</span> of your
          personal data. This enables you to ask us to delete or remove personal
          data where there is no good reason for us continuing to process it.
          You also have the right to ask us to delete or remove your personal
          data where you have successfully exercised your right to object to
          processing (see below), where we may have processed your information
          unlawfully or where we are required to erase your personal data to
          comply with local law. Note, however, that we may not always be able
          to comply with your request of erasure for specific legal reasons
          which will be notified to you, if applicable, at the time of your
          request.
        </p>

        <p>
          <span className="nw-terms-text-bold">Object to processing</span> of
          your personal data where we are relying on a legitimate interest (or
          those of a third party) and there is something about your particular
          situation which makes you want to object to processing on this ground
          as you feel it impacts on your fundamental rights and freedoms. You
          also have the right to object where we are processing your personal
          data for direct marketing purposes. In some cases, we may demonstrate
          that we have compelling legitimate grounds to process your information
          which override your rights and freedoms.
        </p>

        <p>
          <span className="nw-terms-text-bold">
            Request restriction of processing
          </span>{" "}
          of your personal data. This enables you to ask us to suspend the
          processing of your personal data in the following scenarios:
        </p>

        <p>· If you want us to establish the data's accuracy.</p>

        <p>
          · Where our use of the data is unlawful but you do not want us to
          erase it.
        </p>

        <p>
          · Where you need us to hold the data even if we no longer require it
          as you need it to establish, exercise or defend legal claims.
        </p>

        <p>
          · You have objected to our use of your data but we need to verify
          whether we have overriding legitimate grounds to use it.
        </p>

        <p>
          <span className="nw-terms-text-bold">Request the transfer</span> of
          your personal data to you or to a third party. We will provide to you,
          or a third party you have chosen, your personal data in a structured,
          commonly used, machine-readable format. Note that this right only
          applies to automated information which you initially provided consent
          for us to use or where we used the information to perform a contract
          with you.
        </p>

        <p>
          <span className="nw-terms-text-bold">
            Withdraw consent at any time
          </span>{" "}
          where we are relying on consent to process your personal data.
          However, this will not affect the lawfulness of any processing carried
          out before you withdraw your consent. If you withdraw your consent, we
          may not be able to provide certain products or services to you. We
          will advise you if this is the case at the time you withdraw your
          consent.
        </p>
      </div>
      <Footer theme="light" />
    </div>
  );
}
