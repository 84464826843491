import React, { useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from "react-router-dom";

import "./App.css";

import Landing from "./pages/Landing/Landing";
import Terms from "./pages/Terms/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import AcceptableUsePolicy from "./pages/AcceptableUsePolicy/AcceptableUsePolicy";

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}; 

function App() {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/acceptable-use" element={<AcceptableUsePolicy />} />
          {/* <Route path="/cookies-policy" element={<Landing />} /> */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
