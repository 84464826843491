import React from "react";
import { Link } from "react-router-dom";

import useDeviceType from "../../hooks/useDeviceType";

import "./Footer.css";

export default function Footer({ theme = "dark" }: { theme?: "dark" | "light" }) {
  const device = useDeviceType();
  return (
    <div className={`footer footer-${theme}`}>
      {device !== "mobile" && (
        <div className="footer-details-container">
          <div className="footer-details">
            <div>
              <h4 className="footer-header">About Network</h4>
              <div className="footer-links">
                <p className="footer-link">Company information</p>
                <p className="footer-link">Our mission</p>
                <p className="footer-link">Our team</p>
              </div>
            </div>
            <div>
              <h4 className="footer-header">Security</h4>
              <div className="footer-links">
                <FooterLink
                  theme={theme}
                  to="/terms"
                  text="Terms and Conditions"
                />
                <FooterLink
                  theme={theme}
                  to="/privacy-policy"
                  text="Privacy policy"
                />
                <FooterLink
                  theme={theme}
                  to="/acceptable-use"
                  text="Acceptable use policy"
                />
              </div>
            </div>
            <div>
              <h4 className="footer-header">Contact Details</h4>
              <div className="footer-contact">
                <img
                  src="./images/email.png"
                  height="10px"
                  alt="contact email"
                />
                <p className="footer-link footer-email">
                  support@networktheapp.com
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {device === "mobile" && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <FooterLink theme={theme} to="/terms" text="Terms and Conditions" />
          <FooterLink
            theme={theme}
            to="/privacy-policy"
            text="Privacy policy"
          />
          <FooterLink
            theme={theme}
            to="/acceptable-use"
            text="Acceptable use policy"
          />
          <div className="footer-contact">
            <img src="./images/email.png" height="10px" alt="contact email" />
            <p className="footer-link footer-email">
              support@networktheapp.com
            </p>
          </div>
        </div>
      )}
      <div className={`copyright-info copyright-info-${theme}`}>
        <p>
          Copyright © Network Company PLC. All rights reserved.
          Networktheapp.com is a social network mobile app.
        </p>
      </div>
    </div>
  );
}

function FooterLink(props: {
  to: string;
  text: string;
  theme: "dark" | "light"
}) {
  return (
    <Link className={`footer-link footer-link-${props.theme}`} to={props.to}>
      {props.text}
    </Link>
  )
}